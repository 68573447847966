@media only screen and (max-width : 767px) {
    /* Default CSS */
    body {
        font-size: 14px;
    }
    .ptb-100 {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .pt-100 {
        padding-top: 50px;
    }
    .ptb-70 {
        padding-top: 20px;
        padding-bottom: 50px;
    }
    .pb-100 {
        padding-bottom: 50px;
    }
    .pt-70 {
        padding-top: 30px;
    }
    .pb-70 {
        padding-bottom: 20px;
    }
    .pt-45 {
        padding-top: 30px;
    }
    .default-btn {
        padding: 11px 25px;
    }
    .default-btn-two {
        padding: 11px 25px;
    }
    .section-title {
        span {
            font-size: 12px;
        }
        h2 {
            font-size: 26px;
        }
        p {
            padding-top: 10px;
        }
        .section-icon {
            .icon {
                font-size: 30px;
            }
            &::before {
                top: 15px;
                left: 30px;
                height: 1px;
            }
            &::after {
                top: 15px;
                right: 30px;
                height: 1px;
            }
        }
    }
    h3 {
        font-size: 20px;
    }
    /* Default CSS End */

    /* Top Header CSS */
    .top-header-bg-two {
        background-color: #1fa2ff;
    }
    .header-left {
        text-align: center;
        .header-left-card {
            ul {
                li {
                    padding-left: 0;
                    margin: 0 7px;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    &::before {
                        display: none;
                    }
                    &:first-child::before {
                        display: none;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                    .head-icon {
                        left: 0;
                        top: 2px;
                        transform: translateY(-0%);
                        font-size: 20px;
                    }
                    a {
                        margin-top: 0;
                        font-size: 14px;
                    }
                }
            }
        } 
    }
    .header-right {
        float: none;
        max-width: 270px;
        margin: 0 auto;
        padding-left: 12px;
    }
    .top-social-link {
        float: none;
        margin: 0 auto;
        max-width: 270px;
        text-align: center;
        padding-top: 4px;
        padding-bottom: 4px;
    }
    /* Top Header CSS End */

    /* Navbar CSS */
    .mobile-nav {
        .logo {
            img {
                max-width: 120px;
                height: 40px;
                margin-top: -5px;
            }
            .logo-two {
                display: none;
            }
            .logo-one {
                display: inline-block;
            }
        }
    }
    .others-options {
        display: none !important;
    }
    .side-nav-responsive {
        display: block;
    }
    .side-nav-responsive {
        .container {
            position: relative;
            display: flex;
            .container {
                position: absolute;
                top: 55px;
                right: -20px;
                max-width: 185px;
                margin-left: auto;
                opacity: 0;
                visibility: hidden;
                transition: 0.7s;
                transform: scaleX(0);
                z-index: 2;
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
    .search-overlay.search-popup {
        width: 260px;
        margin-top: 15px;
    }
    /* Navbar CSS End */

    /* Main Banner CSS */
    .banner-content {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        padding-top: 120px;
        padding-bottom: 30px;
        h1 {
            margin-top: 10px;
            font-size: 35px;
            margin-bottom: 15px;
        }
        p {
            margin-bottom: 25px;
            font-size: 16px;
        }
    }
    .banner-img {
        position: inherit;
        right: 0;
        left: 0;
        bottom: 0;
        img {
            max-width: 100%;
        }
    }
    .banner-shape {
        display: none;
    }
    .banner-area-two {
        margin-top: 0;
    }
    .banner-content-two {
        padding-top: 120px;
        padding-bottom: 30px;
        text-align: center;
        h1 {
            margin-top: 10px;
            font-size: 35px;
            margin-bottom: 15px;
        }
        p {
            margin-bottom: 25px;
            font-size: 16px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .banner-img-2 {
        position: inherit;
        right: 0;
        right: 0;
        bottom: 0;
        text-align: center;
        img {
            max-width: 100%;
        }
    }
    .home-banner-content {
        padding-top: 120px;
        padding-bottom: 30px;
        text-align: center;
        h1 {
            margin-top: 10px;
            font-size: 35px;
            margin-bottom: 15px;
        }
        p {
            margin-bottom: 25px;
            font-size: 16px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .home-banner-form {
        padding-top: 0;
        padding-bottom: 40px;
    }
    .banner-bottom-card {
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
        i {
            font-size: 24px;
            width: 55px;
            height: 55px;
            line-height: 55px;
            top: 0px;
        }
        .content {
            padding-left: 65px;
            span {
                font-weight: 500;
                font-size: 14px;
            }
            h3 {
                font-size: 20px;
                margin-top: 5px;
                margin-bottom: 0;
            }
        }
    }
    /* Main Banner CSS End */
    .first-slider {
        &.owl-carousel {
            &.owl-theme {
                .owl-nav {
                    [class*=owl-] {
                        width: 45px;
                        height: 45px;
                        line-height: 42px;
                        left: 0;
                        &.owl-next {
                            right: 0;
                        }
                    }
                }
            }
        }
    }
    .second-slider {
        &.owl-carousel {
            &.owl-theme {
                .owl-nav {
                    [class*=owl-] {
                        left: 0;
                        width: 45px;
                        height: 45px;
                        line-height: 42px;
                        &.owl-next {
                            right: 0;
                        }
                    }
                }
            }
        }
    }
    .third-slider {
        &.owl-carousel {
            &.owl-theme {
                .owl-nav {
                    position: absolute;
                    left: 0;
                    right: 0;
                    [class*=owl-] {
                        position: relative;
                        left: 0;
                        right: 0;
                        margin: auto;
                        top: auto;
                        bottom: 80px;
                        &.owl-next {
                            right: 0;
                            left: 10px;
                        }
                    }
                }

                .home-banner {
                    padding-bottom: 70px;
                }
            }
        }
    }
    .four-slider {
        &.owl-carousel {
            &.owl-theme {
                .home-banner {
                    video {
                        width: auto;
                        height: 1325px;
                    }
                }

                .owl-nav {
                    [class*=owl-] {
                        left: 0;
                        right: 60px;
                        margin: auto;
                        top: auto;
                        bottom: -12px;
                        &.owl-next {
                            right: 0;
                            left: 60px;
                        }
                    }
                }
            }
        }
    }

    /* Inner Banner */
    .inner-banner {
        .inner-title {
            padding-top: 120px;
            padding-bottom: 60px;
            h3 {
                font-size: 26px;
            }
            ul {
                li {
                    font-size: 16px;
                }
            }
        }
    }
    .inner-banner-shape {
        display: none;
    }
    /* Inner Banner End */

    /* About Area CSS */
    .about-content {
        margin-left: 0;
        .section-title {
            margin-bottom: 20px;
        }
        .about-card {
            margin-bottom: 15px;
        }
        .about-btn {
            margin-top: 20px;
        }
    }
    .about-right-img {
        margin-bottom: 30px;
        .about-open-hours {
            bottom: 0;
            position: inherit;
            left: 0;
            right: 0;
            margin: 0 auto;
            padding: 30px 15px;
            width: 100%;
        }
    }
    /* About Area CSS End */

    .section-title {
        .section-icon {
            width: 100%;
        }
    }

    /* appointment Area CSS */
    .appointment-shape {
        display: none;
    }
    .appointment-from  {
        padding: 30px 20px;
        h2 {
            font-size: 26px;
        }
        p {
            font-size: 16px;
            margin-bottom: 20px;
        }
    }
    .appointment-from-area {
        padding-top: 50px;
        padding-bottom: 0;
    }
    .appointment-img-2 {
        position: inherit;
        bottom: 0;
        right: 0;
        left: 0;
        margin: 0 auto;
        text-align: center;
    }
    .appointment-widget-right {
        margin-bottom: 30px;
        .appointment-hours {
            bottom: 0;
            position: inherit;
            left: 0;
            right: 0;
            margin: 0 auto;
            padding: 30px 15px;
            width: 100%;
        }
    }
    /* appointment Area CSS End */

    /* Service Area CSS End */
    .service-dots {
        display: none;
    }
    .service-card {
        &:hover {
            .service-content {
                top: 35%;
                transform: translateY(-27%);
                bottom: auto;
            }
        }
        .service-content {
            p {
                max-width: 245px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
    .service-dots-2 {
        display: none;
    }
    .service-details-shape {
        display: none;
    }
    .services-article { 
        h2 {
            font-size: 24px;
            margin-bottom: 15px;
        }
        .services-content {
            p {
                margin-bottom: 20px;
            }
        }
        .services-another-content {
            .content-img {
                margin-top: 20px;
                margin-bottom: 20px;
                .row {
                    .col-6 {
                        padding-left: 7px !important;
                        padding-right: 7px !important;
                    }
                }
            }
        }
       
        .services-specialist {
            h2 {
                margin-bottom: 20px;
            }
        }
    }
    /* Service Area CSS End */

    /* Product Area CSS */
    .product-shape {
        display: none;
    }
    .product-details-shape {
        display: none;
    }
    .product-search-widget {
        margin-bottom: 20px;
    }
    .product-title {
        h2 {
            margin-bottom: 30px;
        }
    }

    .product-slider {
        margin-bottom: 80px;
        .owl-nav {
            .owl-prev {
                top: auto;
                right: auto;
                bottom: -50px;
                left: 32%;
            }
            .owl-next {
                top: auto;
                right: 32%;
                bottom: -50px;
            }
        }
    }

    .product-desc {
        h3 {
            margin-bottom: 10px;
            font-size: 20px;
            font-weight: 600;
        }
        .product-add-btn {
            .default-btn {
                margin-right: 10px;
            }
        }
    }
    .products-details-tab {
        .tabs {
            li {
                a {
                    font-size: 15px;
                    padding: 12px 24px;
                }
            }
        }
    }
    .products-tabs-reviews {
        ul {
            li {
                padding-left: 0;
                padding-bottom: 30px;
                img {
                    position: initial;
                    top: 0;
                    left: 0;
                    margin-bottom: 20px;
                }
            }
        }
    }
    /* Product Area CSS End */

    /* Cart Wraps Area */
    .cart-wraps-area {
        .cart-table {
            text-align: center;
            table  {
                thead {
                    tr {
                        th {
                            padding: 0 40px 10px;
                            white-space: nowrap;
                            font-size: 16px;
                            &:first-child {
                                text-align: left;
                                padding-left: 0;
                            }
                        }
                        td {
                            white-space: nowrap;
                            &:first-child {
                                text-align: left;
                                padding-left: 0;
                            }
                        }
                    }
                }
            }
        }
        .cart-buttons {
            text-align: center;
            .text-right {
                text-align: center !important;
                margin-top: 0;
            }
            .default-btn {
                display: block;
                text-align: center;
                width: 100%;
            }
        }
        .cart-totals {
            padding: 20px;
            margin-top: 35px;
            h3 {
                margin-bottom: 18px;
                font-size: 20px;
            }
            ul {
                li {
                    font-size: 14px;
                }
            }
            .default-btn {
                display: block;
                text-align: center;
                width: 100%;
            }
        }
        .cart-wraps {
            .continue-shopping-box {
                margin-bottom: 20px;
            }
        }
        .cart-calc {
            padding: 20px !important;
            margin-top: 30px;
            .cart-wraps-form {
                h3 {
                    font-size: 18px;
                }
                .form-group {
                    select {
                        width: 100%;
                    }
                }
                .default-btn {
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }
    /* Cart Wraps Area End */

    /* Checkout Area */
    .billing-details {
        padding: 30px;
        h3 {
            font-size: 20px;
        }
        .form-group {
            margin-bottom: 15px;
        }
    }
    .order-details {
        .order-table {
            padding:30px 15px;
            h3 {
                font-size: 20px;
            }
            table {
                thead {
                    tr {
                        th {
                           padding: 15px 10px;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
        .payment-box {
            padding: 30px;
        }
    }
    /* Checkout Area End */
    
    /* Consultancy Area CSS */
    .consultancy-area {
        &::before {
            top: 0;
            width: 100%;
            height: 100%;
        }
        &::after {
            top: 0;
            width: 100%;
            height: 100%;
        }
    }
    .consultancy-img {
        margin-top: 50px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        img {
            text-align: center;
        }
    }
    .consultancy-content {
        margin-top: 10px;
        margin-left: 0;
        h2 {
            font-size: 26px;
        }
        p {
            margin-bottom: 25px;
            color: #ffffff;
        }
        .consultancy-form {
            padding: 30px 20px;
            margin-bottom: 50px;
        }
    }
    /* Consultancy Area CSS End */

    .departments-shape {
        display: none;
    }

    /* Prescription Area CSS */
    .prescription-content {
        margin-right: auto;
        margin-left: auto;
        padding-left: 30px;
        padding-right: 30px;
        h2 {
            font-size: 26px;
        }
        p {
            margin-bottom: 15px;
            color: #ffffff;
            font-size: 16px;
        }
        .prescription-btn {
            padding: 11px 25px;
        }
    }
    .prescription-shape {
        display: none;
    }
    .prescription-shape-2 {
        display: none;
    }
    /* Prescription Area CSS End */

    /* Insurance Area CSS */
    .insurance-area {
        margin-top: 0;
        margin-bottom: 0;
    }
    .insurance-content {
        padding-top: 50px;
        padding-bottom: 30px;
        h2 {
            font-size: 26px;
        }
        p {
            margin-bottom: 15px;
            color: #ffffff;
            font-size: 16px;
        }
    }
    .insurance-img {
        position: inherit;
        z-index: 1;
        bottom: 0;
        right: 0;
        left: 0;
        padding-bottom: 35px;
        img {
            max-width: 100%;
        }
    }
    /* Insurance Area CSS End */

    /* Doctors Area CSS */
    .doctors-shape {
        display: none;
    }
    .doctor-tab {
        .tabs {
            li {
                margin-right: 5px;
                margin-bottom: 10px;
                &:last-child {
                    margin-bottom: 0;
                }
                a {
                    font-size: 14px;
                    padding: 10px 16px;
                }
            }
        }
    }
    .doctor-tab-shape {
        display: none;
    }
    .doctors-details-content {
        h3 {
            font-size: 24px;
        }
        span {
            font-size: 16px;
        }
    }
    .doctors-details-shape {
        display: none;
    }
    /* Doctors Area CSS End */

    /* Serve Area CSS */
    .serve-area {
        .section-title {
            margin-bottom: 30px;
        }
    }
    .serve-btn-area {
        padding-top: 100px;
        padding-bottom: 100px;
        &::before {
            top: 20px;
            width: 90%;
            height: 88%;
        }
    }
    /* Serve Area CSS End */
    
    /* Case Study Area CSS */
    .case-study-card {
        .content {
            top: 18%;
            p {
                margin-bottom: 7px;
                max-width: 245px;
            }
            .read-more-btn {
                font-size: 15px;
            }
        }
    }
    /* Case Study Area CSS End */

    /* Emergency Area CSS */
    .emergency-shape {
        display: none;
    }
    .emergency-content {
        position: relative;
        z-index: 1;
        h2 {
            font-size: 26px;
        }
        p {
            font-size: 16px;
        }
        .emergency-icon-content {
            padding-left: 55px;
            position: relative;
            i {
                font-size: 45px;
                top: -5px;
            }
            h3 {
                font-size: 20px;
            }
        }
    }
    .emergency-area-bg {
        &::before {
            top: 0;
            width: 100%;
            height: 100%;
        }
    }
    .emergency-shape-2 {
        display: none;
    }
    .emergency-content-ptb {
        margin-top: 45px;
        margin-left: 30px;
        margin-bottom: 30px;
    }
    .emergency-img {
        text-align: center;
    }
    /* Emergency Area CSS End */

    /* Blog Area CSS */
    .blog-shape-icon {
        display: none;
    }
    .blog-area {
        .section-title {
            h2 {
                margin-top: 0 !important;
                line-height: 1 !important;
            }
        }
    }
    .blog-more-btn {
        margin-top: 0px;
    }
    .blog-shape-icon {
        display: none;
    }
    .blog-article { 
        .article-content {
            h2 {
                font-size: 24px;
            }
            p {
                margin-bottom: 20px;
            }
        }
        .another-content {
            .content-img {
                margin-top: 20px;
                margin-bottom: 20px;
                .row {
                    .col-6 {
                        padding-left: 7px !important;
                        padding-right: 7px !important;
                    }
                }
            }
            p {
                margin-bottom: 15px;
            }
        }
        .blog-article-share {
            margin-bottom: 30px;
            .blog-tag {
                margin-bottom: 20px;
            }
            .social-icon {
                float: none;
            }
        }
        .blog-article-more {
            h2 {
                font-size: 24px;
                margin-bottom: 20px;
            }
        }
        .comments-wrap {
            padding-top: 0;
            .title {
                font-size: 24px;
                margin-bottom: 20px;
            }
            ul {
                li {
                    padding-left: 0;
                    &.ml-60 {
                        margin-left: 0;
                    }
                    img {
                        position: inherit;
                        margin-bottom: 15px;
                    }
                    a {
                        position: inherit;
                        right: 0;
                        margin-top: 15px;
                    }
                }
            }
        }
    }
    .side-bar-widget {
        .blog-gallery {
           max-width: 380px;
        }
    }
    
    /* Blog Area CSS End */

    /* faq Area CSS */
    .faq-bg {
        &::before {
            display: none;
        }
        &::after {
            display: none;
        }
    }
    .faq-accordion {
        .accordion {
            .accordion-title {
                font-size: 16px;
                i {
                    font-size: 20px;
                    width: 35px;
                    height: 35px;
                    line-height: 35px;
                }
            }
        }
    }
    .faq-counter-area {
        margin-left: auto;
        margin-right: auto;
    }
    .faq-counter-area {
        .col-lg-6 {
            &:nth-child(2){
                .single-counter {
                    margin-top: 0;
                }
            }
            &:nth-child(3){
                .single-counter {
                    margin-top: 0;
                }
            }
        }
    }
    /* faq Area CSS End */
    
    /* Testimonials Area CSS */
    .testimonials-slider-area {
        position: relative;
        .owl-nav {
            margin-top: 0;
            .owl-prev {
               top: unset;
               bottom: 40px;
               left: 30%;
            }
            .owl-next {
                top: unset;
                bottom: 40px;
                left: auto;
                right: 30%;
            }
        }
    }
    .testimonials-slider {
        padding: 30px 20px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
        &::before {
            display: none;
        }
        .testimonials-item {
            p {
                font-size: 16px;
                color: #ffffff;
                margin-bottom: 20px;
            }
            .content {
                margin-bottom: 80px;
            }
        } 
    }
    .testimonials-slider-two {
        &::before {
            display: none;
        }
    }

    .testimonials-slider-two {
        margin-bottom: 100px;
        padding: 20px 30px 40px;
        .testimonials-card {
            p {
                font-size: 16px;
                color: #ffffff;
                margin-bottom: 20px;
            }
        } 
        .owl-nav {
            .owl-prev {
               bottom: -75px;
               left: 30%;
            }
            .owl-next {
                bottom: -75px;
                right: 30%;
            }
        }
    }
    /* Testimonials Area CSS End */

    .case-study-shape {
        display: none;
    }
    .case-details-shape {
        display: none;
    }
    .case-article {
        .case-article-content {
            h2 {
                font-size: 24px;
            }
        }
    }
    
    /* Brand Area CSS */
    .brand-area {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    /* Brand Area CSS End */

    /* Subscribe Area CSS */
    
    .newsletter-area {
        max-width: 100%;
        h2 {
            font-size: 26px;
        }
        p {
            font-size: 16px;
            margin-bottom: 20px;
        }
    }
    .newsletter-form {
        .subscribe-btn {
            position: inherit;
            top: 20px;
            border-radius: 5px;
            margin-bottom: 30px;
        }
    }
    .subscribe-shape {
        display: none;
    }
    .subscribe-responsive {
        margin-left: 15px;
        margin-right: 15px;
    }
    /* Subscribe Area CSS End */

    /* Contact Area CSS */
    .contact-widget-right {
        h3 {
            font-size: 26px;
        }
    }
    .contact-form {
        h2 {
            font-size: 26px;
        }
    }
    .contact-shape {
        display: none;
    }
    /* Contact Area CSS End */
    .user-all-form {
        .contact-form {
            .forget {
                float: none;
            }
        }
    }

    .pagination-area {
        margin-top: 0;
    }

    /* Footer Area CSS */
    .footer-widget {
        h3 {
            margin-bottom: 20px;
            font-size: 22px;
        }
        p {
            margin-bottom: 15px;
        }
        .footer-logo {
            margin-bottom: 20px;
        }
    }

    .footer-widget.ps-2, .footer-widget.px-2 {
        padding-left: 0 !important;
    }
    .footer-widget.ps-3, .footer-widget.px-3 {
        padding-left: 0 !important;
    }
    /* Footer Area CSS End */

    /* Error Area */
    .error-area {
        .error-content {
            padding-top: 70px;
            padding-bottom: 70px;
            h1 {
                font-size: 120px;
            }
            h3 {
                margin-top: 20px;
                font-size: 26px;
            }
            p {
                font-size: 15px;
                margin-top: 10px;
                padding: 0 15px;
            }
        }
    }
    /* Error Area End */

    /* Coming Soon */
    .coming-soon-area {
        .coming-soon-content {
            padding-top: 30px;
            padding-bottom: 20px;
            padding-left: 15px;
            padding-right: 15px;
            h1 {
                font-size: 30px;
                line-height: 1.1;
                margin-top: 0;
            }
            p {
                font-size: 14px;
                margin-top: 15px;
            }
            #timer {
                margin-top: 10px;
                div {
                    font-size: 30px;
                    margin-left: 5px;
                    margin-right: 5px;
                    span {
                        font-size: 14px;
                        margin-top: -5px;
                    }
                    &::before {
                        display: none;
                    }
                }
            }
            .newsletter-form {
                margin-top: 20px;
                margin-bottom: 20px;
                .input-newsletter {
                    height: 55px;
                    padding-left: 13px;
                    font-size: 15px;
                }
                button {
                    position: relative;
                    font-size: 14px;
                    margin-top: 15px;
                    width: 100%;
                    height: 50px;
                }
            }
        }
    }
    /* Coming Soon End */
}

@media only screen and (min-width : 576px) and (max-width : 767px) {
    .testimonials-slider-area {
        position: relative;
        .owl-nav {
            margin-top: 0;
            .owl-prev {
               top: unset;
               bottom: 40px;
               left: 38%;
            }
            .owl-next {
                top: unset;
                bottom: 40px;
                left: auto;
                right: 38%;
            }
        }
    }
    .testimonials-slider-two {
        .owl-nav {
            .owl-prev {
               right: 38%;
            }
            .owl-next {
                right: 38%;
            }
        }
    }

    .product-slider {
        .owl-nav {
            .owl-prev {
                left: 38%;
            }
            .owl-next {
                top: auto;
                right: 38%;
            }
        }
    }
     
    .service-card {
        &:hover {
            .service-content {
                top: 40%;
                transform: translateY(-27%);
                bottom: auto;
            }
        }
        .service-content {
            p {
                max-width: 345px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    .cart-wraps-area {
        .cart-wraps {
            .continue-shopping-box {
                margin-bottom: 0;
            }
        }
    }

    .user-all-form {
        .contact-form {
            .forget {
                float: right;
            }
        }
    }

    .case-study-card {
        &::after {
            width: 96%;
        }
        &:hover::after {
            opacity: 0.8;
            height: 95%;
        }
        .content {
            top: 30%;
            p {
                max-width: 320px;
            }
        }
    }

    .serve-btn-area {
        &::before {
            top: 20px;
            width: 93%;
            height: 88%;
        }
    }

    .four-slider {
        &.owl-carousel {
            &.owl-theme {
                .owl-nav {
                    [class*=owl-] {
                        bottom: 40px;
                    }
                }
                .home-banner {
                    video {
                        height: 1150px;
                    }
                    .home-banner-content {
                        padding-top: 30px;
                    }
                }
            }
        }
    }
    
}

@media only screen and (min-width : 768px) and (max-width : 991px) {
    /* Default CSS */
    body {
        font-size: 14px;
    }
    .ptb-100 {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .pt-100 {
        padding-top: 50px;
    }
    .ptb-70 {
        padding-top: 20px;
        padding-bottom: 50px;
    }
    .pb-100 {
        padding-bottom: 50px;
    }
    .pt-70 {
        padding-top: 30px;
    }
    .pb-70 {
        padding-bottom: 20px;
    }
    .pt-45 {
        padding-top: 30px;
    }
    .default-btn {
        padding: 11px 25px;
    }
    .default-btn-two {
        padding: 11px 25px;
    }
    .section-title {
        span {
            font-size: 12px;
        }
        h2 {
            font-size: 26px;
        }
        p {
            padding-top: 10px;
        }
        .section-icon {
            .icon {
                font-size: 30px;
            }
            &::before {
                top: 15px;
                left: 30px;
                height: 1px;
            }
            &::after {
                top: 15px;
                right: 30px;
                height: 1px;
            }
        }
    }
    h3 {
        font-size: 20px;
    }
    /* Default CSS End */

    /* Top Header CSS */
    .top-header-bg-two {
        background-color: #1fa2ff;
    }
    .header-left {
        .header-left-card {
            ul {
                li {
                    margin-right: 15px;
                    &::before {
                        height: 20px;
                        left: -8px;
                    }
                }
            }
        }
    }
    .top-social-link {
        ul {
            li {
                display: inline-block;
                margin-right: 3px;
            }
        }
    }
    /* Top Header CSS End */

    /* Navbar CSS */
    .mobile-nav {
        .logo {
            img {
                max-width: 120px;
                height: 40px;
                margin-top: -5px;
            }
            .logo-two {
                display: none;
            }
            .logo-one {
                display: inline-block;
            }
        }
    }
    .others-options {
        display: none !important;
    }
    .side-nav-responsive {
        display: block;
    }
    .side-nav-responsive {
        .container {
            position: relative;
            display: flex;
            .container {
                position: absolute;
                top: 55px;
                right: -20px;
                max-width: 185px;
                margin-left: auto;
                opacity: 0;
                visibility: hidden;
                transition: 0.7s;
                transform: scaleX(0);
                z-index: 2;
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
    .search-overlay.search-popup {
        width: 260px;
        margin-top: 15px;
    }
    /* Navbar CSS End */

    /* Main Banner CSS */
    .banner-content {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        padding-top: 120px;
        padding-bottom: 30px;
        h1 {
            margin-top: 10px;
            font-size: 35px;
            margin-bottom: 15px;
        }
        p {
            margin-bottom: 25px;
            font-size: 16px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .banner-img {
        position: inherit;
        right: 0;
        left: 0;
        bottom: 0;
        img {
            max-width: 100%;
        }
    }
    .banner-shape {
        display: none;
    }
    .banner-area-two {
        margin-top: 0;
    }
    .banner-content-two {
        padding-top: 120px;
        padding-bottom: 30px;
        text-align: center;
        h1 {
            margin-top: 10px;
            font-size: 35px;
            margin-bottom: 15px;
        }
        p {
            margin-bottom: 25px;
            font-size: 16px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .banner-img-2 {
        position: inherit;
        right: 0;
        right: 0;
        bottom: 0;
        text-align: center;
        img {
            max-width: 100%;
        }
    }
    .banner-bottom-card {
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
        i {
            font-size: 24px;
            width: 55px;
            height: 55px;
            line-height: 55px;
            top: 0px;
        }
        .content {
            padding-left: 65px;
            span {
                font-weight: 500;
                font-size: 14px;
            }
            h3 {
                font-size: 20px;
                margin-top: 5px;
                margin-bottom: 0;
            }
            p {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
    .home-banner-content {
        padding-top: 120px;
        padding-bottom: 30px;
        text-align: center;
        h1 {
            margin-top: 10px;
            font-size: 35px;
            margin-bottom: 15px;
        }
        p {
            margin-bottom: 25px;
            font-size: 16px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .home-banner-form {
        padding-top: 0;
        padding-bottom: 40px;
    }
    /* Main Banner CSS End */
    .first-slider {
        &.owl-carousel {
            &.owl-theme {
                .owl-nav {
                    [class*=owl-] {
                        left: 15px;
                        &.owl-next {
                            right: 15px;
                        }
                    }
                }
            }
        }
    }
    .second-slider {
        &.owl-carousel {
            &.owl-theme {
                .owl-nav {
                    [class*=owl-] {
                        left: 65px;
                        &.owl-next {
                            right: 65px;
                        }
                    }
                }
            }
        }
    }
    .third-slider {
        &.owl-carousel {
            &.owl-theme {
                .home-banner {
                    padding-bottom: 95px;
                }
                .owl-nav {
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    [class*=owl-] {
                        position: relative;
                        left: 0;
                        right: 10px;
                        top: auto;
                        margin: auto;
                        bottom: 60px;
                        &.owl-next {
                            right: 0;
                            left: 10px;
                        }
                    }
                }
            }
        }
    }
    .four-slider {
        &.owl-carousel {
            &.owl-theme {
                .owl-nav {
                    [class*=owl-] {
                        left: 0;
                        right: 60px;
                        margin: auto;
                        top: auto;
                        bottom: 60px;
                        &.owl-next {
                            right: 0;
                            left: 60px;
                        }
                    }
                }
                .home-banner {
                    video {
                        width: auto;
                        height: 1170px;
                    }
                    .home-banner-content {
                        padding-top: 0;
                    }
                }
            }
        }
    }

    /* Inner Banner */
    .inner-banner {
        .inner-title {
            padding-top: 140px;
            padding-bottom: 80px;
            h3 {
                font-size: 26px;
            }
            ul {
                li {
                    font-size: 16px;
                }
            }
        }
    }
    .inner-banner-shape {
        display: none;
    }
    /* Inner Banner End */

    /* About Area CSS */
    .about-content {
        margin-left: 0;
        .section-title {
            margin-bottom: 20px;
        }
        .about-card {
            margin-bottom: 15px;
        }
        .about-btn {
            margin-top: 20px;
        }
    }
    /* About Area CSS End */

    /* appointment Area CSS */
    .appointment-shape {
        display: none;
    }
    .appointment-img {
        text-align: center;
    }
    .appointment-from  {
        padding: 40px 25px;
        h2 {
            font-size: 26px;
        }
        p {
            font-size: 16px;
            margin-bottom: 20px;
        }
    }
    .appointment-from-area {
        padding-top: 50px;
        padding-bottom: 0;
    }
    .appointment-img-2 {
        position: inherit;
        bottom: 0;
        right: 0;
        left: 0;
        margin: 0 auto;
        text-align: center;
    }
    /* appointment Area CSS End */

    /* Service Area CSS End */
    .service-dots {
        display: none;
    }
    .service-details-shape {
        display: none;
    }
    .service-card {
        &:hover {
            .service-content {
                top: 35%;
                transform: translateY(-27%);
                bottom: auto;
            }
        }
        .service-content {
            p {
                max-width: 245px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
    .service-dots-2 {
        display: none;
    }
    /* Service Area CSS End */

    /* Product Area CSS */
    .product-shape {
        display: none;
    }
    .product-details-shape {
        display: none;
    }
    /* Product Area CSS End */

    /* Consultancy Area CSS */
    .consultancy-area {
        &::before {
            top: 0;
            width: 100%;
            height: 100%;
        }
        &::after {
            top: 0;
            width: 100%;
            height: 100%;
        }
    }
    .consultancy-img {
        margin-top: 50px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        margin-bottom: -30px;
        img {
            text-align: center;
        }
    }
    .consultancy-content {
        margin-top: 10px;
        margin-left: 0;
        h2 {
            font-size: 26px;
        }
        p {
            margin-bottom: 25px;
            color: #ffffff;
        }
        .consultancy-form {
            padding: 40px 25px;
            margin-bottom: 50px;
        }
    }
    /* Consultancy Area CSS End */

    .departments-shape {
        display: none;
    }

    /* Prescription Area CSS */
    .prescription-content {
        margin-right: auto;
        margin-left: auto;
        padding-left: 30px;
        padding-right: 30px;
        h2 {
            font-size: 26px;
        }
        p {
            margin-bottom: 15px;
            color: #ffffff;
            font-size: 16px;
        }
        .prescription-btn {
            padding: 11px 25px;
        }
    }
    .prescription-shape {
        display: none;
    }
    .prescription-shape-2 {
        img {
            max-width: 490px;
        }
    }
    /* Prescription Area CSS End */

    /* Insurance Area CSS */
    .insurance-area {
        margin-top: 0;
        margin-bottom: 0;
    }
    .insurance-content {
        padding-top: 50px;
        padding-bottom: 30px;
        h2 {
            font-size: 26px;
        }
        p {
            margin-bottom: 15px;
            color: #ffffff;
            font-size: 16px;
        }
    }
    .insurance-img {
        position: inherit;
        z-index: 1;
        bottom: 0;
        right: 0;
        left: 0;
        padding-bottom: 35px;
        img {
            max-width: 100%;
        }
    }
    /* Insurance Area CSS End */

    /* Doctors Area CSS */
    .doctors-shape {
        display: none;
    }
    .doctor-tab {
        .tabs {
            li {
                margin-right: 5px;
                a {
                    font-size: 14px;
                    padding: 10px 16px;
                }
            }
        }
    }
    .doctor-tab-shape {
        display: none;
    }
    .doctors-details-content {
        h3 {
            font-size: 24px;
        }
        span {
            font-size: 16px;
        }
    }
    .doctors-details-shape {
        display: none;
    }
    /* Doctors Area CSS End */

    /* Serve Area CSS */
    .serve-area {
        .section-title {
            margin-bottom: 30px;
        }
    }
    .serve-btn-area {
        &::before {
            top: 20px;
            width: 95%;
            height: 93%;
        }
    }
    /* Serve Area CSS End */

    /* Emergency Area CSS */
    .emergency-shape {
        display: none;
    }
    .emergency-content {
        position: relative;
        z-index: 1;
        h2 {
            font-size: 26px;
        }
        p {
            font-size: 16px;
        }
        .emergency-icon-content {
            padding-left: 55px;
            position: relative;
            i {
                font-size: 45px;
                top: -5px;
            }
            h3 {
                font-size: 20px;
            }
        }
    }
    .emergency-area-bg {
        &::before {
            top: 0;
            width: 100%;
            height: 100%;
        }
    }
    .emergency-shape-2 {
        display: none;
    }
    .emergency-content-ptb {
        margin-top: 30px;
        margin-left: 30px;
        margin-bottom: 30px;
    }
    .emergency-img {
        text-align: center;
    }
    /* Emergency Area CSS End */

    /* faq Area CSS */
    .faq-bg {
        &::before {
            display: none;
        }
        &::after {
            display: none;
        }
    }
    .faq-accordion {
        .accordion {
            .accordion-title {
                font-size: 16px;
                i {
                    font-size: 20px;
                    width: 35px;
                    height: 35px;
                    line-height: 35px;
                }
            }
        }
    }
    .faq-counter-area {
        margin-left: auto;
        margin-right: auto;
    }
    .faq-counter-area {
        .col-lg-6 {
            &:nth-child(2){
                .single-counter {
                    margin-top: 0;
                }
            }
            &:nth-child(3){
                .single-counter {
                    margin-top: 0;
                }
            }
        }
    }
    /* faq Area CSS End */

    /* Blog Area CSS */
    .blog-shape-icon {
        display: none;
    }
    .blog-area {
        .section-title {
            h2 {
                margin-top: 0 !important;
                line-height: 1 !important;
            }
        }
    }
    .blog-more-btn {
        margin-top: 0px;
    }
    .side-bar-widget {
        .blog-gallery {
           max-width: 380px;
        }
    }
    /* Blog Area CSS End */

    /* Testimonials Area CSS */
    .testimonials-slider-area {
        position: relative;
        .owl-nav {
            margin-top: 0;
            .owl-prev {
               top: unset;
               bottom: 40px;
               left: 42%;
            }
            .owl-next {
                top: unset;
                bottom: 40px;
                left: auto;
                right: 42%;
            }
        }
    }
    .testimonials-slider {
        padding: 30px 20px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
        &::before {
            display: none;
        }
        .testimonials-item {
            p {
                font-size: 16px;
                color: #ffffff;
                margin-bottom: 20px;
                max-width: 600px;
                margin-left: auto;
                margin-right: auto;
            }
            .content {
                margin-bottom: 80px;
            }
        } 
    }

    .testimonials-slider-two {
        margin-bottom: 100px;
        padding: 30px 30px 50px;
        &::before {
            display: none;
        }
        .owl-nav {
            .owl-prev {
               bottom: -75px;
               left: 42%;
            }
            .owl-next {
                bottom: -75px;
                right: 42%;
            }
        }
    }
    
    /* Testimonials Area CSS End */

    .case-study-shape {
        display: none;
    }
    .case-details-shape {
        display: none;
    }
    .case-article {
        .case-article-content {
            h2 {
                font-size: 24px;
            }
        }
    }

    /* Brand Area CSS */
    .brand-area {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    /* Brand Area CSS End */
    .subscribe-img {
        img {
            height: auto;
        }
    }
    .newsletter-area {
        h2 {
            font-size: 26px;
        }
        p {
            font-size: 16px;
            margin-bottom: 20px;
        }
    }
    .subscribe-shape {
        display: none;
    }
    /* Contact Area CSS */
    .contact-widget-right {
        h3 {
            font-size: 26px;
        }
    }
    .contact-form {
        h2 {
            font-size: 26px;
        }
    }
    .contact-shape {
        display: none;
    }
    /* Contact Area CSS End */

    .pagination-area {
        margin-top: 0;
    }

    /* Footer Area CSS */
    .footer-widget {
        h3 {
            margin-bottom: 20px;
            font-size: 22px;
        }
        p {
            margin-bottom: 15px;
        }
        .footer-logo {
            margin-bottom: 20px;
        }
    }
    .footer-widget.ps-2, .footer-widget.px-2 {
        padding-left: 0 !important;
    }
    .footer-widget.ps-3, .footer-widget.px-3 {
        padding-left: 0 !important;
    }
    /* Footer Area CSS End */

    /* Error Area */
    .error-area {
        .error-content {
            padding-top: 70px;
            padding-bottom: 70px;
            h1 {
                font-size: 120px;
            }
            h3 {
                margin-top: 20px;
                font-size: 30px;
            }
            p {
                font-size: 15px;
                margin-top: 10px;
                padding: 0 15px;
            }
        }
    }
    /* Error Area End */

    /* Coming Soon */
    .coming-soon-area {
        .coming-soon-content {
            padding-top: 30px;
            padding-bottom: 20px;
            padding-left: 15px;
            padding-right: 15px;
            h1 {
                font-size: 40px;
                line-height: 1.1;
                margin-top: 0;
            }
            p {
                font-size: 14px;
                margin-top: 15px;
            }
            #timer {
                margin-top: 10px;
                div {
                    font-size: 35px;
                    margin-left: 15px;
                    margin-right: 15px;
                    span {
                        font-size: 14px;
                        margin-top: -5px;
                    }
                    &::before {
                        display: none;
                    }
                }
            }
            .newsletter-form {
                margin-top: 20px;
            }
        }
    }
    /* Coming Soon End */

}

@media only screen and (min-width : 992px) and (max-width : 1199px) {
    .navbar-light .navbar-brand img {
        max-width: 120px;
    }
    .main-nav {
        nav {
            .navbar-nav {
                .nav-item {
                    a {
                        margin-left: 10px;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
    /* Main Banner CSS */
    .banner-content {
        h1 {
            font-size: 50px;
        }
        p {
            max-width: 500px;
        }
    }
    .banner-img {
        bottom: -5px;
        img {
            max-width: 650px;
        }
    }
    .banner-content-two {
        h1 {
            font-size: 50px;
        }
    }
    .banner-img-2 {
        right: 0;
        img {
            max-width: 550px;
        }
    }
    .home-banner-content {
        h1 {
            font-size: 48px;
        }
    }
     
    /* Main Banner CSS End */
    .first-slider {
        &.owl-carousel {
            &.owl-theme {
                img {
                    max-width: 600px;
                }
                .owl-nav {
                    [class*=owl-] {
                        width: 40px;
                        height: 40px;
                        line-height: 40px;
                        left: 5px;
                        &.owl-next {
                            right: 0;
                        }
                    }
                }
            }
        }
    }
    .second-slider {
        &.owl-carousel {
            &.owl-theme {
                .owl-nav {
                    [class*=owl-] {
                        left: 0;
                        &.owl-next {
                            right: 0;
                        }
                    }
                }
            }
        }
    }
    .third-slider {
        &.owl-carousel {
            &.owl-theme {
                .owl-nav {
                    [class*=owl-] {
                        left: 0;
                        top: auto;
                        right: 60px;
                        bottom: 20px;
                        margin: auto;
                        &.owl-next {
                            right: 0;
                            left: 60px;
                        }
                    }
                }
            }
        }
    }
    .four-slider {
        &.owl-carousel {
            &.owl-theme {
                .owl-nav {
                    [class*=owl-] {
                        left: 0;
                        right: 60px;
                        margin: auto;
                        top: auto;
                        bottom: 20px;
                        &.owl-next {
                            right: 0;
                            left: 60px;
                        }
                    }
                }
                .home-banner {
                    video {
                        width: auto;
                        height: 800px;
                    }
                    .home-banner-content {
                        padding-top: 0;
                    }
                }
            }
        }
    }

    .inner-banner-shape {
        .shape2 {
            display: none;
        }
    }

    .about-right-img {
        .about-open-hours {
            bottom: -150px;
        }
    }
    
    .service-dots {
       img {
           max-width: 260px;
       }
    }
    .service-dots-2 {
        img {
            max-width: 300px;
        }
    }
    .service-open-hours {
        padding: 30px 10px;
    }

    .about-content {
        margin-left: 0;
    }

    .appointment-from {
        padding: 30px 20px;
        p {
            margin-bottom: 20px;
        }
        .form-group {
            margin-bottom: 15px;
        }
        .default-btn {
            margin-top: 5px;
        }
    }

    .appointment-img-2 {
        right: 0;
        img {
            max-width: 650px;
        }
    }

    .appointment-widget-right {
        .appointment-hours {
            bottom: -100px;
        }
    }

    .consultancy-area {
        &::before {
            height: 79.1%;
        }
        &::after {
            height: 79.1%;
        }
    }
    .consultancy-content {
        .consultancy-form {
            padding: 40px 25px;
        }
    }

    .emergency-shape-2 {
        img {
            max-width: 856px;
        }
    }
    .emergency-content-ptb {
        h2 {
            font-size: 34px !important;
        }
    }
    .emergency-area-bg {
        &::before {
            height: 92%;
        }
    }

    .prescription-content {
        margin-left: 70px;
        h2 {
            font-size: 34px;
        }
        p {
            font-size: 18px;
        }
    }
    .prescription-shape {
        img {
            max-width: 720px;
        }
    }
    .prescription-shape-2 {
        img {
            max-width: 720px;
        }
    }
    
    .doctors-shape {
        .doctors-shape-1 {
            img {
                max-width: 260px;
            }
        }
        .doctors-shape-2 {
            img {
                max-width: 260px;
            }
        }
    }

    .case-study-card {
        .content {
            top: 15%;
            p {
                margin-bottom: 7px;
                max-width: 225px;
                font-size: 14px;
            }
            .read-more-btn {
                font-size: 15px;
            }
        }
    }

    .blog-shape-icon {
        top: -195px;
        font-size: 300px;
    }
    .blog-card {
        .content {
            h3 {
                font-size: 18px;
            }
        }
    }

    .blog-card-side {
        ul {
            li {
                a {
                    h3 {
                        font-size: 18px;
                    }
                }
            }
        }
    }

    .testimonials-slider {
        padding: 50px 30px;
        margin-left: -80px;
        margin-right: 80px;
        &::before {
            top: 130px;
            font-size: 425px;
        }
    }
    .testimonials-slider-two {
        &::before {
            top: 108px;
        }
        .owl-nav {
            .owl-prev {
               left: 44%;
            }
            .owl-next {
                right: 44%;
            }
        }
    }

    .single-counter {
        padding: 60px 8px;
    }
    
    .newsletter-area {
        max-width: 510px;
    }
    .footer-widget {
        h3 {
            font-size: 20px;
        }
        .footer-contact-list {
            li {
                font-size: 15px;
            }
        }
         
        .footer-list {
            li {
                font-size: 15px;
            }
        }
        .social-link {
            li {
                margin-right: 0px;
            }
        }
        
    }
}

@media only screen and (min-width : 1200px) and (max-width : 1399px) {

    .four-slider {
        &.owl-carousel {
            &.owl-theme {
                .owl-nav {
                    [class*=owl-] {
                        left: 0;
                        right: 70px;
                        top: auto;
                        bottom: 80px;
                        margin: auto;
                        &.owl-next {
                            right: 0;
                            left: 70px;
                        }
                    }
                }
                .home-banner {
                    video {
                        width: auto;
                    }
                }
            }
        }
    }

    //
    .third-slider {
        &.owl-carousel {
            &.owl-theme {
                .owl-nav {
                    [class*=owl-] {
                        left: 0;
                        &.owl-next {
                            right: 0;
                        }
                    }
                }
            }
        }
    }
    .banner-area {
        .banner-img {
            bottom: -50px;
        }
    }

    .owl-carousel .owl-item img {
        display: block;
        max-width: 700px;
    }

    //
    .second-slider {
        &.owl-carousel {
            &.owl-theme {
                .owl-nav {
                    [class*=owl-] {
                        left: 5px;
                        &.owl-next {
                            right: 30px;
                        }
                    }
                }
            }
        }
    }

    //
    .first-slider {
        &.owl-carousel {
            &.owl-theme {
                .owl-nav {
                    [class*=owl-] {
                        left: 5px;
                        &.owl-next {
                            right: 5px;
                        }
                    }
                }
            }
        }
    }

}

@media only screen and (min-width : 1400px) {
    .nav-bar {
        max-width: 1370px;
    }
    
    .banner-content {
        h1 {
            font-size: 65px;
        }
    }
    
    .about-img {
        img {
            width: 100%;
        }
    }
    .case-study-card {
        .content {
            top: 22%;
        }
        &:hover {
            &::after {
                height: 93.4%;
            }
        }
    }
   
    .appointment-from {
        padding: 35px 30px;
    }
    .testimonials-slider {
        padding: 80px 60px;
        &::before {
            top: 190px;
        }
        .testimonials-item {
            p {
                max-width: 450px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
    .emergency-img {
        img {
            max-width: 445px;
        }
    }
    .case-article {
        .case-details-img {
            img {
                width: 100%;
            }
        }
        .case-article-other {
            .article-img {
                img {
                    width: 100%;
                }
            }
        }
    }
    .terms-conditions-img {
        img {
            width: 100%;
        }
    }
    .services-article {
        .services-article-img {
            img {
                width: 100%;
            }
        }
    }
    .service-open-hours {
        max-width: 100%;
    }

    .testimonials-slider-two {
        &::before {
            top: 285px;
        }
    }
}

@media only screen and (min-width : 1800px) {
    .banner-img {
        right: 5%;
        img {
            max-width: 1080px;
        }
    }
    .banner-img-2 {
        right: 15%;
        img {
            max-width: 100%;
        }
    }
    .emergency-shape {
        left: 5%;
    }
    .appointment-img-2 {
        right: 20%;
    }
    .appointment-shape {
        left: 34%;
    }
    .service-dots {
        position: absolute;
        top: 0;
        left: 0;
    }
    .service-details-shape {
        img {
            max-width: 100%;
        }
    }

    .product-details-shape {
        img {
            max-width: 100%;
        }
    }

    .case-study-shape {
        img {
            max-width: 100%;
        }
    }
    .case-details-shape {
        img {
            max-width: 100%;
        }
    }

    .doctor-tab-shape {
        .shape1 {
            img {
                max-width: 100%;
            }
        }
        .shape2 {
            img {
                max-width: 100%;
            }
        }
    }

    .insurance-img {
        right: 15%;
        img {
            max-width: 600px;
        }
    }

    .contact-shape {
        img {
            max-width: 100%;
        }
    }
    
    .subscribe-shape {
        left: 25%;
    }
}

@media (max-width: 992px) {
    .banner-content {
      padding-top: 150px; // Üstteki boşlukları azalt
      padding-bottom: 150px; // Alttaki boşlukları azalt
      h1 {
        font-size: 40px; // Başlık boyutunu küçült
      }
      p {
        max-width: 90%; // Daha fazla alana yayılmasını sağla
      }
    }
  
    .banner-img-left, .banner-img-right {
      display: none; // Küçük ekranlarda yan resimleri gizle
    }
  }
  
  @media (max-width: 576px) {
    .banner-content {
      h1 {
        font-size: 30px; // Daha küçük başlık boyutu
      }
      p {
        font-size: 14px; // Daha küçük yazı boyutu
      }
      .appointment-btn {
        padding: 10px 20px; // Buton boyutunu küçült
      }
    }
    
    .banner-bottom {
      .banner-bottom-card {
        flex-direction: column; // Kartları dikey hizala
        align-items: center; // Ortala
        i {
          margin-bottom: 10px; // İkon ile içerik arasında boşluk
        }
      }
    }
  }
  