@media only screen and (max-width: 767px) {
    body {
        font-size:14px
    }

    .ptb-100 {
        padding-top: 50px;
        padding-bottom: 50px
    }

    .pt-100 {
        padding-top: 50px
    }

    .ptb-70 {
        padding-top: 20px;
        padding-bottom: 50px
    }

    .pb-100 {
        padding-bottom: 50px
    }

    .pt-70 {
        padding-top: 30px
    }

    .pb-70 {
        padding-bottom: 20px
    }

    .pt-45 {
        padding-top: 30px
    }

    .default-btn {
        padding: 11px 25px
    }

    .default-btn-two {
        padding: 11px 25px
    }

    .section-title span {
        font-size: 12px
    }

    .section-title h2 {
        font-size: 26px
    }

    .section-title p {
        padding-top: 10px
    }

    .section-title .section-icon .icon {
        font-size: 30px
    }

    .section-title .section-icon::before {
        top: 15px;
        left: 30px;
        height: 1px
    }

    .section-title .section-icon::after {
        top: 15px;
        right: 30px;
        height: 1px
    }

    h3 {
        font-size: 20px
    }

    .top-header-bg-two {
        background-color: #1fa2ff
    }

    .header-left {
        text-align: center
    }

    .header-left .header-left-card ul li {
        padding-left: 0;
        margin: 0 7px;
        padding-top: 5px;
        padding-bottom: 5px
    }

    .header-left .header-left-card ul li::before {
        display: none
    }

    .header-left .header-left-card ul li:first-child::before {
        display: none
    }

    .header-left .header-left-card ul li:last-child {
        margin-right: 0
    }

    .header-left .header-left-card ul li .head-icon {
        left: 0;
        top: 2px;
        transform: translateY(0%);
        font-size: 20px
    }

    .header-left .header-left-card ul li a {
        margin-top: 0;
        font-size: 14px
    }

    .header-right {
        float: none;
        max-width: 270px;
        margin: 0 auto;
        padding-left: 12px
    }

    .top-social-link {
        float: none;
        margin: 0 auto;
        max-width: 270px;
        text-align: center;
        padding-top: 4px;
        padding-bottom: 4px
    }

    .mobile-nav .logo img {
        max-width: 120px;
        height: 40px;
        margin-top: -5px
    }

    .mobile-nav .logo .logo-two {
        display: none
    }

    .mobile-nav .logo .logo-one {
        display: inline-block
    }

    .others-options {
        display: none!important
    }

    .side-nav-responsive {
        display: block
    }

    .side-nav-responsive .container {
        position: relative;
        display: flex
    }

    .side-nav-responsive .container .container {
        position: absolute;
        top: 55px;
        right: -20px;
        max-width: 185px;
        margin-left: auto;
        opacity: 0;
        visibility: hidden;
        transition: .7s;
        transform: scaleX(0);
        z-index: 2;
        padding-left: 15px;
        padding-right: 15px
    }

    .search-overlay.search-popup {
        width: 260px;
        margin-top: 15px
    }

    .banner-content {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        padding-top: 120px;
        padding-bottom: 30px
    }

    .banner-content h1 {
        margin-top: 10px;
        font-size: 35px;
        margin-bottom: 15px
    }

    .banner-content p {
        margin-bottom: 25px;
        font-size: 16px
    }

    .banner-img {
        position: inherit;
        right: 0;
        left: 0;
        bottom: 0
    }

    .banner-img img {
        max-width: 100%
    }

    .banner-shape {
        display: none
    }

    .banner-area-two {
        margin-top: 0
    }

    .banner-content-two {
        padding-top: 120px;
        padding-bottom: 30px;
        text-align: center
    }

    .banner-content-two h1 {
        margin-top: 10px;
        font-size: 35px;
        margin-bottom: 15px
    }

    .banner-content-two p {
        margin-bottom: 25px;
        font-size: 16px;
        margin-left: auto;
        margin-right: auto
    }

    .banner-img-2 {
        position: inherit;
        right: 0;
        right: 0;
        bottom: 0;
        text-align: center
    }

    .banner-img-2 img {
        max-width: 100%
    }

    .home-banner-content {
        padding-top: 120px;
        padding-bottom: 30px;
        text-align: center
    }

    .home-banner-content h1 {
        margin-top: 10px;
        font-size: 35px;
        margin-bottom: 15px
    }

    .home-banner-content p {
        margin-bottom: 25px;
        font-size: 16px;
        margin-left: auto;
        margin-right: auto
    }

    .home-banner-form {
        padding-top: 0;
        padding-bottom: 40px
    }

    .banner-bottom-card {
        max-width: 300px;
        margin-left: auto;
        margin-right: auto
    }

    .banner-bottom-card i {
        font-size: 24px;
        width: 55px;
        height: 55px;
        line-height: 55px;
        top: 0
    }

    .banner-bottom-card .content {
        padding-left: 65px
    }

    .banner-bottom-card .content span {
        font-weight: 500;
        font-size: 14px
    }

    .banner-bottom-card .content h3 {
        font-size: 20px;
        margin-top: 5px;
        margin-bottom: 0
    }

    .first-slider.owl-carousel.owl-theme .owl-nav [class*=owl-] {
        width: 45px;
        height: 45px;
        line-height: 42px;
        left: 0
    }

    .first-slider.owl-carousel.owl-theme .owl-nav [class*=owl-].owl-next {
        right: 0
    }

    .second-slider.owl-carousel.owl-theme .owl-nav [class*=owl-] {
        left: 0;
        width: 45px;
        height: 45px;
        line-height: 42px
    }

    .second-slider.owl-carousel.owl-theme .owl-nav [class*=owl-].owl-next {
        right: 0
    }

    .third-slider.owl-carousel.owl-theme .owl-nav {
        position: absolute;
        left: 0;
        right: 0
    }

    .third-slider.owl-carousel.owl-theme .owl-nav [class*=owl-] {
        position: relative;
        left: 0;
        right: 0;
        margin: auto;
        top: auto;
        bottom: 80px
    }

    .third-slider.owl-carousel.owl-theme .owl-nav [class*=owl-].owl-next {
        right: 0;
        left: 10px
    }

    .third-slider.owl-carousel.owl-theme .home-banner {
        padding-bottom: 70px
    }

    .four-slider.owl-carousel.owl-theme .home-banner video {
        width: auto;
        height: 1325px
    }

    .four-slider.owl-carousel.owl-theme .owl-nav [class*=owl-] {
        left: 0;
        right: 60px;
        margin: auto;
        top: auto;
        bottom: -12px
    }

    .four-slider.owl-carousel.owl-theme .owl-nav [class*=owl-].owl-next {
        right: 0;
        left: 60px
    }

    .inner-banner .inner-title {
        padding-top: 120px;
        padding-bottom: 60px
    }

    .inner-banner .inner-title h3 {
        font-size: 26px
    }

    .inner-banner .inner-title ul li {
        font-size: 16px
    }

    .inner-banner-shape {
        display: none
    }

    .about-content {
        margin-left: 0
    }

    .about-content .section-title {
        margin-bottom: 20px
    }

    .about-content .about-card {
        margin-bottom: 15px
    }

    .about-content .about-btn {
        margin-top: 20px
    }

    .about-right-img {
        margin-bottom: 30px
    }

    .about-right-img .about-open-hours {
        bottom: 0;
        position: inherit;
        left: 0;
        right: 0;
        margin: 0 auto;
        padding: 30px 15px;
        width: 100%
    }

    .section-title .section-icon {
        width: 100%
    }

    .appointment-shape {
        display: none
    }

    .appointment-from {
        padding: 30px 20px
    }

    .appointment-from h2 {
        font-size: 26px
    }

    .appointment-from p {
        font-size: 16px;
        margin-bottom: 20px
    }

    .appointment-from-area {
        padding-top: 50px;
        padding-bottom: 0
    }

    .appointment-img-2 {
        position: inherit;
        bottom: 0;
        right: 0;
        left: 0;
        margin: 0 auto;
        text-align: center
    }

    .appointment-widget-right {
        margin-bottom: 30px
    }

    .appointment-widget-right .appointment-hours {
        bottom: 0;
        position: inherit;
        left: 0;
        right: 0;
        margin: 0 auto;
        padding: 30px 15px;
        width: 100%
    }

    .service-dots {
        display: none
    }

    .service-card:hover .service-content {
        top: 35%;
        transform: translateY(-27%);
        bottom: auto
    }

    .service-card .service-content p {
        max-width: 245px;
        margin-left: auto;
        margin-right: auto
    }

    .service-dots-2 {
        display: none
    }

    .service-details-shape {
        display: none
    }

    .services-article h2 {
        font-size: 24px;
        margin-bottom: 15px
    }

    .services-article .services-content p {
        margin-bottom: 20px
    }

    .services-article .services-another-content .content-img {
        margin-top: 20px;
        margin-bottom: 20px
    }

    .services-article .services-another-content .content-img .row .col-6 {
        padding-left: 7px!important;
        padding-right: 7px!important
    }

    .services-article .services-specialist h2 {
        margin-bottom: 20px
    }

    .product-shape {
        display: none
    }

    .product-details-shape {
        display: none
    }

    .product-search-widget {
        margin-bottom: 20px
    }

    .product-title h2 {
        margin-bottom: 30px
    }

    .product-slider {
        margin-bottom: 80px
    }

    .product-slider .owl-nav .owl-prev {
        top: auto;
        right: auto;
        bottom: -50px;
        left: 32%
    }

    .product-slider .owl-nav .owl-next {
        top: auto;
        right: 32%;
        bottom: -50px
    }

    .product-desc h3 {
        margin-bottom: 10px;
        font-size: 20px;
        font-weight: 600
    }

    .product-desc .product-add-btn .default-btn {
        margin-right: 10px
    }

    .products-details-tab .tabs li a {
        font-size: 15px;
        padding: 12px 24px
    }

    .products-tabs-reviews ul li {
        padding-left: 0;
        padding-bottom: 30px
    }

    .products-tabs-reviews ul li img {
        position: initial;
        top: 0;
        left: 0;
        margin-bottom: 20px
    }

    .cart-wraps-area .cart-table {
        text-align: center
    }

    .cart-wraps-area .cart-table table thead tr th {
        padding: 0 40px 10px;
        white-space: nowrap;
        font-size: 16px
    }

    .cart-wraps-area .cart-table table thead tr th:first-child {
        text-align: left;
        padding-left: 0
    }

    .cart-wraps-area .cart-table table thead tr td {
        white-space: nowrap
    }

    .cart-wraps-area .cart-table table thead tr td:first-child {
        text-align: left;
        padding-left: 0
    }

    .cart-wraps-area .cart-buttons {
        text-align: center
    }

    .cart-wraps-area .cart-buttons .text-right {
        text-align: center!important;
        margin-top: 0
    }

    .cart-wraps-area .cart-buttons .default-btn {
        display: block;
        text-align: center;
        width: 100%
    }

    .cart-wraps-area .cart-totals {
        padding: 20px;
        margin-top: 35px
    }

    .cart-wraps-area .cart-totals h3 {
        margin-bottom: 18px;
        font-size: 20px
    }

    .cart-wraps-area .cart-totals ul li {
        font-size: 14px
    }

    .cart-wraps-area .cart-totals .default-btn {
        display: block;
        text-align: center;
        width: 100%
    }

    .cart-wraps-area .cart-wraps .continue-shopping-box {
        margin-bottom: 20px
    }

    .cart-wraps-area .cart-calc {
        padding: 20px!important;
        margin-top: 30px
    }

    .cart-wraps-area .cart-calc .cart-wraps-form h3 {
        font-size: 18px
    }

    .cart-wraps-area .cart-calc .cart-wraps-form .form-group select {
        width: 100%
    }

    .cart-wraps-area .cart-calc .cart-wraps-form .default-btn {
        width: 100%;
        text-align: center
    }

    .billing-details {
        padding: 30px
    }

    .billing-details h3 {
        font-size: 20px
    }

    .billing-details .form-group {
        margin-bottom: 15px
    }

    .order-details .order-table {
        padding: 30px 15px
    }

    .order-details .order-table h3 {
        font-size: 20px
    }

    .order-details .order-table table thead tr th {
        padding: 15px 10px;
        font-size: 16px
    }

    .order-details .payment-box {
        padding: 30px
    }

    .consultancy-area::before {
        top: 0;
        width: 100%;
        height: 100%
    }

    .consultancy-area::after {
        top: 0;
        width: 100%;
        height: 100%
    }

    .consultancy-img {
        margin-top: 50px;
        margin-left: auto;
        margin-right: auto;
        text-align: center
    }

    .consultancy-img img {
        text-align: center
    }

    .consultancy-content {
        margin-top: 10px;
        margin-left: 0
    }

    .consultancy-content h2 {
        font-size: 26px
    }

    .consultancy-content p {
        margin-bottom: 25px;
        color: #fff
    }

    .consultancy-content .consultancy-form {
        padding: 30px 20px;
        margin-bottom: 50px
    }

    .departments-shape {
        display: none
    }

    .prescription-content {
        margin-right: auto;
        margin-left: auto;
        padding-left: 30px;
        padding-right: 30px
    }

    .prescription-content h2 {
        font-size: 26px
    }

    .prescription-content p {
        margin-bottom: 15px;
        color: #fff;
        font-size: 16px
    }

    .prescription-content .prescription-btn {
        padding: 11px 25px
    }

    .prescription-shape {
        display: none
    }

    .prescription-shape-2 {
        display: none
    }

    .insurance-area {
        margin-top: 0;
        margin-bottom: 0
    }

    .insurance-content {
        padding-top: 50px;
        padding-bottom: 30px
    }

    .insurance-content h2 {
        font-size: 26px
    }

    .insurance-content p {
        margin-bottom: 15px;
        color: #fff;
        font-size: 16px
    }

    .insurance-img {
        position: inherit;
        z-index: 1;
        bottom: 0;
        right: 0;
        left: 0;
        padding-bottom: 35px
    }

    .insurance-img img {
        max-width: 100%
    }

    .doctors-shape {
        display: none
    }

    .doctor-tab .tabs li {
        margin-right: 5px;
        margin-bottom: 10px
    }

    .doctor-tab .tabs li:last-child {
        margin-bottom: 0
    }

    .doctor-tab .tabs li a {
        font-size: 14px;
        padding: 10px 16px
    }

    .doctor-tab-shape {
        display: none
    }

    .doctors-details-content h3 {
        font-size: 24px
    }

    .doctors-details-content span {
        font-size: 16px
    }

    .doctors-details-shape {
        display: none
    }

    .serve-area .section-title {
        margin-bottom: 30px
    }

    .serve-btn-area {
        padding-top: 100px;
        padding-bottom: 100px
    }

    .serve-btn-area::before {
        top: 20px;
        width: 90%;
        height: 88%
    }

    .case-study-card .content {
        top: 18%
    }

    .case-study-card .content p {
        margin-bottom: 7px;
        max-width: 245px
    }

    .case-study-card .content .read-more-btn {
        font-size: 15px
    }

    .emergency-shape {
        display: none
    }

    .emergency-content {
        position: relative;
        z-index: 1
    }

    .emergency-content h2 {
        font-size: 26px
    }

    .emergency-content p {
        font-size: 16px
    }

    .emergency-content .emergency-icon-content {
        padding-left: 55px;
        position: relative
    }

    .emergency-content .emergency-icon-content i {
        font-size: 45px;
        top: -5px
    }

    .emergency-content .emergency-icon-content h3 {
        font-size: 20px
    }

    .emergency-area-bg::before {
        top: 0;
        width: 100%;
        height: 100%
    }

    .emergency-shape-2 {
        display: none
    }

    .emergency-content-ptb {
        margin-top: 45px;
        margin-left: 30px;
        margin-bottom: 30px
    }

    .emergency-img {
        text-align: center
    }

    .blog-shape-icon {
        display: none
    }

    .blog-area .section-title h2 {
        margin-top: 0!important;
        line-height: 1!important
    }

    .blog-more-btn {
        margin-top: 0
    }

    .blog-shape-icon {
        display: none
    }

    .blog-article .article-content h2 {
        font-size: 24px
    }

    .blog-article .article-content p {
        margin-bottom: 20px
    }

    .blog-article .another-content .content-img {
        margin-top: 20px;
        margin-bottom: 20px
    }

    .blog-article .another-content .content-img .row .col-6 {
        padding-left: 7px!important;
        padding-right: 7px!important
    }

    .blog-article .another-content p {
        margin-bottom: 15px
    }

    .blog-article .blog-article-share {
        margin-bottom: 30px
    }

    .blog-article .blog-article-share .blog-tag {
        margin-bottom: 20px
    }

    .blog-article .blog-article-share .social-icon {
        float: none
    }

    .blog-article .blog-article-more h2 {
        font-size: 24px;
        margin-bottom: 20px
    }

    .blog-article .comments-wrap {
        padding-top: 0
    }

    .blog-article .comments-wrap .title {
        font-size: 24px;
        margin-bottom: 20px
    }

    .blog-article .comments-wrap ul li {
        padding-left: 0
    }

    .blog-article .comments-wrap ul li.ml-60 {
        margin-left: 0
    }

    .blog-article .comments-wrap ul li img {
        position: inherit;
        margin-bottom: 15px
    }

    .blog-article .comments-wrap ul li a {
        position: inherit;
        right: 0;
        margin-top: 15px
    }

    .side-bar-widget .blog-gallery {
        max-width: 380px
    }

    .faq-bg::before {
        display: none
    }

    .faq-bg::after {
        display: none
    }

    .faq-accordion .accordion .accordion-title {
        font-size: 16px
    }

    .faq-accordion .accordion .accordion-title i {
        font-size: 20px;
        width: 35px;
        height: 35px;
        line-height: 35px
    }

    .faq-counter-area {
        margin-left: auto;
        margin-right: auto
    }

    .faq-counter-area .col-lg-6:nth-child(2) .single-counter {
        margin-top: 0
    }

    .faq-counter-area .col-lg-6:nth-child(3) .single-counter {
        margin-top: 0
    }

    .testimonials-slider-area {
        position: relative
    }

    .testimonials-slider-area .owl-nav {
        margin-top: 0
    }

    .testimonials-slider-area .owl-nav .owl-prev {
        top: unset;
        bottom: 40px;
        left: 30%
    }

    .testimonials-slider-area .owl-nav .owl-next {
        top: unset;
        bottom: 40px;
        left: auto;
        right: 30%
    }

    .testimonials-slider {
        padding: 30px 20px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px
    }

    .testimonials-slider::before {
        display: none
    }

    .testimonials-slider .testimonials-item p {
        font-size: 16px;
        color: #fff;
        margin-bottom: 20px
    }

    .testimonials-slider .testimonials-item .content {
        margin-bottom: 80px
    }

    .testimonials-slider-two::before {
        display: none
    }

    .testimonials-slider-two {
        margin-bottom: 100px;
        padding: 20px 30px 40px
    }

    .testimonials-slider-two .testimonials-card p {
        font-size: 16px;
        color: #fff;
        margin-bottom: 20px
    }

    .testimonials-slider-two .owl-nav .owl-prev {
        bottom: -75px;
        left: 30%
    }

    .testimonials-slider-two .owl-nav .owl-next {
        bottom: -75px;
        right: 30%
    }

    .case-study-shape {
        display: none
    }

    .case-details-shape {
        display: none
    }

    .case-article .case-article-content h2 {
        font-size: 24px
    }

    .brand-area {
        padding-top: 50px;
        padding-bottom: 50px
    }

    .newsletter-area {
        max-width: 100%
    }

    .newsletter-area h2 {
        font-size: 26px
    }

    .newsletter-area p {
        font-size: 16px;
        margin-bottom: 20px
    }

    .newsletter-form .subscribe-btn {
        position: inherit;
        top: 20px;
        border-radius: 5px;
        margin-bottom: 30px
    }

    .subscribe-shape {
        display: none
    }

    .subscribe-responsive {
        margin-left: 15px;
        margin-right: 15px
    }

    .contact-widget-right h3 {
        font-size: 26px
    }

    .contact-form h2 {
        font-size: 26px
    }

    .contact-shape {
        display: none
    }

    .user-all-form .contact-form .forget {
        float: none
    }

    .pagination-area {
        margin-top: 0
    }

    .footer-widget h3 {
        margin-bottom: 20px;
        font-size: 22px
    }

    .footer-widget p {
        margin-bottom: 15px
    }

    .footer-widget .footer-logo {
        margin-bottom: 20px
    }

    .footer-widget.ps-2,.footer-widget.px-2 {
        padding-left: 0!important
    }

    .footer-widget.ps-3,.footer-widget.px-3 {
        padding-left: 0!important
    }

    .error-area .error-content {
        padding-top: 70px;
        padding-bottom: 70px
    }

    .error-area .error-content h1 {
        font-size: 120px
    }

    .error-area .error-content h3 {
        margin-top: 20px;
        font-size: 26px
    }

    .error-area .error-content p {
        font-size: 15px;
        margin-top: 10px;
        padding: 0 15px
    }

    .coming-soon-area .coming-soon-content {
        padding-top: 30px;
        padding-bottom: 20px;
        padding-left: 15px;
        padding-right: 15px
    }

    .coming-soon-area .coming-soon-content h1 {
        font-size: 30px;
        line-height: 1.1;
        margin-top: 0
    }

    .coming-soon-area .coming-soon-content p {
        font-size: 14px;
        margin-top: 15px
    }

    .coming-soon-area .coming-soon-content #timer {
        margin-top: 10px
    }

    .coming-soon-area .coming-soon-content #timer div {
        font-size: 30px;
        margin-left: 5px;
        margin-right: 5px
    }

    .coming-soon-area .coming-soon-content #timer div span {
        font-size: 14px;
        margin-top: -5px
    }

    .coming-soon-area .coming-soon-content #timer div::before {
        display: none
    }

    .coming-soon-area .coming-soon-content .newsletter-form {
        margin-top: 20px;
        margin-bottom: 20px
    }

    .coming-soon-area .coming-soon-content .newsletter-form .input-newsletter {
        height: 55px;
        padding-left: 13px;
        font-size: 15px
    }

    .coming-soon-area .coming-soon-content .newsletter-form button {
        position: relative;
        font-size: 14px;
        margin-top: 15px;
        width: 100%;
        height: 50px
    }
}

@media only screen and (min-width: 576px) and (max-width:767px) {
    .testimonials-slider-area {
        position:relative
    }

    .testimonials-slider-area .owl-nav {
        margin-top: 0
    }

    .testimonials-slider-area .owl-nav .owl-prev {
        top: unset;
        bottom: 40px;
        left: 38%
    }

    .testimonials-slider-area .owl-nav .owl-next {
        top: unset;
        bottom: 40px;
        left: auto;
        right: 38%
    }

    .testimonials-slider-two .owl-nav .owl-prev {
        right: 38%
    }

    .testimonials-slider-two .owl-nav .owl-next {
        right: 38%
    }

    .product-slider .owl-nav .owl-prev {
        left: 38%
    }

    .product-slider .owl-nav .owl-next {
        top: auto;
        right: 38%
    }

    .service-card:hover .service-content {
        top: 40%;
        transform: translateY(-27%);
        bottom: auto
    }

    .service-card .service-content p {
        max-width: 345px;
        margin-left: auto;
        margin-right: auto
    }

    .cart-wraps-area .cart-wraps .continue-shopping-box {
        margin-bottom: 0
    }

    .user-all-form .contact-form .forget {
        float: right
    }

    .case-study-card::after {
        width: 96%
    }

    .case-study-card:hover::after {
        opacity: .8;
        height: 95%
    }

    .case-study-card .content {
        top: 30%
    }

    .case-study-card .content p {
        max-width: 320px
    }

    .serve-btn-area::before {
        top: 20px;
        width: 93%;
        height: 88%
    }

    .four-slider.owl-carousel.owl-theme .owl-nav [class*=owl-] {
        bottom: 40px
    }

    .four-slider.owl-carousel.owl-theme .home-banner video {
        height: 1150px
    }

    .four-slider.owl-carousel.owl-theme .home-banner .home-banner-content {
        padding-top: 30px
    }
}

@media only screen and (min-width: 768px) and (max-width:991px) {
    body {
        font-size:14px
    }

    .ptb-100 {
        padding-top: 50px;
        padding-bottom: 50px
    }

    .pt-100 {
        padding-top: 50px
    }

    .ptb-70 {
        padding-top: 20px;
        padding-bottom: 50px
    }

    .pb-100 {
        padding-bottom: 50px
    }

    .pt-70 {
        padding-top: 30px
    }

    .pb-70 {
        padding-bottom: 20px
    }

    .pt-45 {
        padding-top: 30px
    }

    .default-btn {
        padding: 11px 25px
    }

    .default-btn-two {
        padding: 11px 25px
    }

    .section-title span {
        font-size: 12px
    }

    .section-title h2 {
        font-size: 26px
    }

    .section-title p {
        padding-top: 10px
    }

    .section-title .section-icon .icon {
        font-size: 30px
    }

    .section-title .section-icon::before {
        top: 15px;
        left: 30px;
        height: 1px
    }

    .section-title .section-icon::after {
        top: 15px;
        right: 30px;
        height: 1px
    }

    h3 {
        font-size: 20px
    }

    .top-header-bg-two {
        background-color: #1fa2ff
    }

    .header-left .header-left-card ul li {
        margin-right: 15px
    }

    .header-left .header-left-card ul li::before {
        height: 20px;
        left: -8px
    }

    .top-social-link ul li {
        display: inline-block;
        margin-right: 3px
    }

    .mobile-nav .logo img {
        max-width: 120px;
        height: 40px;
        margin-top: -5px
    }

    .mobile-nav .logo .logo-two {
        display: none
    }

    .mobile-nav .logo .logo-one {
        display: inline-block
    }

    .others-options {
        display: none!important
    }

    .side-nav-responsive {
        display: block
    }

    .side-nav-responsive .container {
        position: relative;
        display: flex
    }

    .side-nav-responsive .container .container {
        position: absolute;
        top: 55px;
        right: -20px;
        max-width: 185px;
        margin-left: auto;
        opacity: 0;
        visibility: hidden;
        transition: .7s;
        transform: scaleX(0);
        z-index: 2;
        padding-left: 15px;
        padding-right: 15px
    }

    .search-overlay.search-popup {
        width: 260px;
        margin-top: 15px
    }

    .banner-content {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        padding-top: 120px;
        padding-bottom: 30px
    }

    .banner-content h1 {
        margin-top: 10px;
        font-size: 35px;
        margin-bottom: 15px
    }

    .banner-content p {
        margin-bottom: 25px;
        font-size: 16px;
        margin-left: auto;
        margin-right: auto
    }

    .banner-img {
        position: inherit;
        right: 0;
        left: 0;
        bottom: 0
    }

    .banner-img img {
        max-width: 100%
    }

    .banner-shape {
        display: none
    }

    .banner-area-two {
        margin-top: 0
    }

    .banner-content-two {
        padding-top: 120px;
        padding-bottom: 30px;
        text-align: center
    }

    .banner-content-two h1 {
        margin-top: 10px;
        font-size: 35px;
        margin-bottom: 15px
    }

    .banner-content-two p {
        margin-bottom: 25px;
        font-size: 16px;
        margin-left: auto;
        margin-right: auto
    }

    .banner-img-2 {
        position: inherit;
        right: 0;
        right: 0;
        bottom: 0;
        text-align: center
    }

    .banner-img-2 img {
        max-width: 100%
    }

    .banner-bottom-card {
        max-width: 300px;
        margin-left: auto;
        margin-right: auto
    }

    .banner-bottom-card i {
        font-size: 24px;
        width: 55px;
        height: 55px;
        line-height: 55px;
        top: 0
    }

    .banner-bottom-card .content {
        padding-left: 65px
    }

    .banner-bottom-card .content span {
        font-weight: 500;
        font-size: 14px
    }

    .banner-bottom-card .content h3 {
        font-size: 20px;
        margin-top: 5px;
        margin-bottom: 0
    }

    .banner-bottom-card .content p {
        margin-left: auto;
        margin-right: auto
    }

    .home-banner-content {
        padding-top: 120px;
        padding-bottom: 30px;
        text-align: center
    }

    .home-banner-content h1 {
        margin-top: 10px;
        font-size: 35px;
        margin-bottom: 15px
    }

    .home-banner-content p {
        margin-bottom: 25px;
        font-size: 16px;
        margin-left: auto;
        margin-right: auto
    }

    .home-banner-form {
        padding-top: 0;
        padding-bottom: 40px
    }

    .first-slider.owl-carousel.owl-theme .owl-nav [class*=owl-] {
        left: 15px
    }

    .first-slider.owl-carousel.owl-theme .owl-nav [class*=owl-].owl-next {
        right: 15px
    }

    .second-slider.owl-carousel.owl-theme .owl-nav [class*=owl-] {
        left: 65px
    }

    .second-slider.owl-carousel.owl-theme .owl-nav [class*=owl-].owl-next {
        right: 65px
    }

    .third-slider.owl-carousel.owl-theme .home-banner {
        padding-bottom: 95px
    }

    .third-slider.owl-carousel.owl-theme .owl-nav {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0
    }

    .third-slider.owl-carousel.owl-theme .owl-nav [class*=owl-] {
        position: relative;
        left: 0;
        right: 10px;
        top: auto;
        margin: auto;
        bottom: 60px
    }

    .third-slider.owl-carousel.owl-theme .owl-nav [class*=owl-].owl-next {
        right: 0;
        left: 10px
    }

    .four-slider.owl-carousel.owl-theme .owl-nav [class*=owl-] {
        left: 0;
        right: 60px;
        margin: auto;
        top: auto;
        bottom: 60px
    }

    .four-slider.owl-carousel.owl-theme .owl-nav [class*=owl-].owl-next {
        right: 0;
        left: 60px
    }

    .four-slider.owl-carousel.owl-theme .home-banner video {
        width: auto;
        height: 1170px
    }

    .four-slider.owl-carousel.owl-theme .home-banner .home-banner-content {
        padding-top: 0
    }

    .inner-banner .inner-title {
        padding-top: 140px;
        padding-bottom: 80px
    }

    .inner-banner .inner-title h3 {
        font-size: 26px
    }

    .inner-banner .inner-title ul li {
        font-size: 16px
    }

    .inner-banner-shape {
        display: none
    }

    .about-content {
        margin-left: 0
    }

    .about-content .section-title {
        margin-bottom: 20px
    }

    .about-content .about-card {
        margin-bottom: 15px
    }

    .about-content .about-btn {
        margin-top: 20px
    }

    .appointment-shape {
        display: none
    }

    .appointment-img {
        text-align: center
    }

    .appointment-from {
        padding: 40px 25px
    }

    .appointment-from h2 {
        font-size: 26px
    }

    .appointment-from p {
        font-size: 16px;
        margin-bottom: 20px
    }

    .appointment-from-area {
        padding-top: 50px;
        padding-bottom: 0
    }

    .appointment-img-2 {
        position: inherit;
        bottom: 0;
        right: 0;
        left: 0;
        margin: 0 auto;
        text-align: center
    }

    .service-dots {
        display: none
    }

    .service-details-shape {
        display: none
    }

    .service-card:hover .service-content {
        top: 35%;
        transform: translateY(-27%);
        bottom: auto
    }

    .service-card .service-content p {
        max-width: 245px;
        margin-left: auto;
        margin-right: auto
    }

    .service-dots-2 {
        display: none
    }

    .product-shape {
        display: none
    }

    .product-details-shape {
        display: none
    }

    .consultancy-area::before {
        top: 0;
        width: 100%;
        height: 100%
    }

    .consultancy-area::after {
        top: 0;
        width: 100%;
        height: 100%
    }

    .consultancy-img {
        margin-top: 50px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        margin-bottom: -30px
    }

    .consultancy-img img {
        text-align: center
    }

    .consultancy-content {
        margin-top: 10px;
        margin-left: 0
    }

    .consultancy-content h2 {
        font-size: 26px
    }

    .consultancy-content p {
        margin-bottom: 25px;
        color: #fff
    }

    .consultancy-content .consultancy-form {
        padding: 40px 25px;
        margin-bottom: 50px
    }

    .departments-shape {
        display: none
    }

    .prescription-content {
        margin-right: auto;
        margin-left: auto;
        padding-left: 30px;
        padding-right: 30px
    }

    .prescription-content h2 {
        font-size: 26px
    }

    .prescription-content p {
        margin-bottom: 15px;
        color: #fff;
        font-size: 16px
    }

    .prescription-content .prescription-btn {
        padding: 11px 25px
    }

    .prescription-shape {
        display: none
    }

    .prescription-shape-2 img {
        max-width: 490px
    }

    .insurance-area {
        margin-top: 0;
        margin-bottom: 0
    }

    .insurance-content {
        padding-top: 50px;
        padding-bottom: 30px
    }

    .insurance-content h2 {
        font-size: 26px
    }

    .insurance-content p {
        margin-bottom: 15px;
        color: #fff;
        font-size: 16px
    }

    .insurance-img {
        position: inherit;
        z-index: 1;
        bottom: 0;
        right: 0;
        left: 0;
        padding-bottom: 35px
    }

    .insurance-img img {
        max-width: 100%
    }

    .doctors-shape {
        display: none
    }

    .doctor-tab .tabs li {
        margin-right: 5px
    }

    .doctor-tab .tabs li a {
        font-size: 14px;
        padding: 10px 16px
    }

    .doctor-tab-shape {
        display: none
    }

    .doctors-details-content h3 {
        font-size: 24px
    }

    .doctors-details-content span {
        font-size: 16px
    }

    .doctors-details-shape {
        display: none
    }

    .serve-area .section-title {
        margin-bottom: 30px
    }

    .serve-btn-area::before {
        top: 20px;
        width: 95%;
        height: 93%
    }

    .emergency-shape {
        display: none
    }

    .emergency-content {
        position: relative;
        z-index: 1
    }

    .emergency-content h2 {
        font-size: 26px
    }

    .emergency-content p {
        font-size: 16px
    }

    .emergency-content .emergency-icon-content {
        padding-left: 55px;
        position: relative
    }

    .emergency-content .emergency-icon-content i {
        font-size: 45px;
        top: -5px
    }

    .emergency-content .emergency-icon-content h3 {
        font-size: 20px
    }

    .emergency-area-bg::before {
        top: 0;
        width: 100%;
        height: 100%
    }

    .emergency-shape-2 {
        display: none
    }

    .emergency-content-ptb {
        margin-top: 30px;
        margin-left: 30px;
        margin-bottom: 30px
    }

    .emergency-img {
        text-align: center
    }

    .faq-bg::before {
        display: none
    }

    .faq-bg::after {
        display: none
    }

    .faq-accordion .accordion .accordion-title {
        font-size: 16px
    }

    .faq-accordion .accordion .accordion-title i {
        font-size: 20px;
        width: 35px;
        height: 35px;
        line-height: 35px
    }

    .faq-counter-area {
        margin-left: auto;
        margin-right: auto
    }

    .faq-counter-area .col-lg-6:nth-child(2) .single-counter {
        margin-top: 0
    }

    .faq-counter-area .col-lg-6:nth-child(3) .single-counter {
        margin-top: 0
    }

    .blog-shape-icon {
        display: none
    }

    .blog-area .section-title h2 {
        margin-top: 0!important;
        line-height: 1!important
    }

    .blog-more-btn {
        margin-top: 0
    }

    .side-bar-widget .blog-gallery {
        max-width: 380px
    }

    .testimonials-slider-area {
        position: relative
    }

    .testimonials-slider-area .owl-nav {
        margin-top: 0
    }

    .testimonials-slider-area .owl-nav .owl-prev {
        top: unset;
        bottom: 40px;
        left: 42%
    }

    .testimonials-slider-area .owl-nav .owl-next {
        top: unset;
        bottom: 40px;
        left: auto;
        right: 42%
    }

    .testimonials-slider {
        padding: 30px 20px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px
    }

    .testimonials-slider::before {
        display: none
    }

    .testimonials-slider .testimonials-item p {
        font-size: 16px;
        color: #fff;
        margin-bottom: 20px;
        max-width: 600px;
        margin-left: auto;
        margin-right: auto
    }

    .testimonials-slider .testimonials-item .content {
        margin-bottom: 80px
    }

    .testimonials-slider-two {
        margin-bottom: 100px;
        padding: 30px 30px 50px
    }

    .testimonials-slider-two::before {
        display: none
    }

    .testimonials-slider-two .owl-nav .owl-prev {
        bottom: -75px;
        left: 42%
    }

    .testimonials-slider-two .owl-nav .owl-next {
        bottom: -75px;
        right: 42%
    }

    .case-study-shape {
        display: none
    }

    .case-details-shape {
        display: none
    }

    .case-article .case-article-content h2 {
        font-size: 24px
    }

    .brand-area {
        padding-top: 50px;
        padding-bottom: 50px
    }

    .subscribe-img img {
        height: auto
    }

    .newsletter-area h2 {
        font-size: 26px
    }

    .newsletter-area p {
        font-size: 16px;
        margin-bottom: 20px
    }

    .subscribe-shape {
        display: none
    }

    .contact-widget-right h3 {
        font-size: 26px
    }

    .contact-form h2 {
        font-size: 26px
    }

    .contact-shape {
        display: none
    }

    .pagination-area {
        margin-top: 0
    }

    .footer-widget h3 {
        margin-bottom: 20px;
        font-size: 22px
    }

    .footer-widget p {
        margin-bottom: 15px
    }

    .footer-widget .footer-logo {
        margin-bottom: 20px
    }

    .footer-widget.ps-2,.footer-widget.px-2 {
        padding-left: 0!important
    }

    .footer-widget.ps-3,.footer-widget.px-3 {
        padding-left: 0!important
    }

    .error-area .error-content {
        padding-top: 70px;
        padding-bottom: 70px
    }

    .error-area .error-content h1 {
        font-size: 120px
    }

    .error-area .error-content h3 {
        margin-top: 20px;
        font-size: 30px
    }

    .error-area .error-content p {
        font-size: 15px;
        margin-top: 10px;
        padding: 0 15px
    }

    .coming-soon-area .coming-soon-content {
        padding-top: 30px;
        padding-bottom: 20px;
        padding-left: 15px;
        padding-right: 15px
    }

    .coming-soon-area .coming-soon-content h1 {
        font-size: 40px;
        line-height: 1.1;
        margin-top: 0
    }

    .coming-soon-area .coming-soon-content p {
        font-size: 14px;
        margin-top: 15px
    }

    .coming-soon-area .coming-soon-content #timer {
        margin-top: 10px
    }

    .coming-soon-area .coming-soon-content #timer div {
        font-size: 35px;
        margin-left: 15px;
        margin-right: 15px
    }

    .coming-soon-area .coming-soon-content #timer div span {
        font-size: 14px;
        margin-top: -5px
    }

    .coming-soon-area .coming-soon-content #timer div::before {
        display: none
    }

    .coming-soon-area .coming-soon-content .newsletter-form {
        margin-top: 20px
    }
}

@media only screen and (min-width: 992px) and (max-width:1199px) {
    .navbar-light .navbar-brand img {
        max-width:120px
    }

    .main-nav nav .navbar-nav .nav-item a {
        margin-left: 10px;
        margin-right: 10px
    }

    .banner-content h1 {
        font-size: 50px
    }

    .banner-content p {
        max-width: 500px
    }

    .banner-img {
        bottom: -5px
    }

    .banner-img img {
        max-width: 650px
    }

    .banner-content-two h1 {
        font-size: 50px
    }

    .banner-img-2 {
        right: 0
    }

    .banner-img-2 img {
        max-width: 550px
    }

    .home-banner-content h1 {
        font-size: 48px
    }

    .first-slider.owl-carousel.owl-theme img {
        max-width: 600px
    }

    .first-slider.owl-carousel.owl-theme .owl-nav [class*=owl-] {
        width: 40px;
        height: 40px;
        line-height: 40px;
        left: 5px
    }

    .first-slider.owl-carousel.owl-theme .owl-nav [class*=owl-].owl-next {
        right: 0
    }

    .second-slider.owl-carousel.owl-theme .owl-nav [class*=owl-] {
        left: 0
    }

    .second-slider.owl-carousel.owl-theme .owl-nav [class*=owl-].owl-next {
        right: 0
    }

    .third-slider.owl-carousel.owl-theme .owl-nav [class*=owl-] {
        left: 0;
        top: auto;
        right: 60px;
        bottom: 20px;
        margin: auto
    }

    .third-slider.owl-carousel.owl-theme .owl-nav [class*=owl-].owl-next {
        right: 0;
        left: 60px
    }

    .four-slider.owl-carousel.owl-theme .owl-nav [class*=owl-] {
        left: 0;
        right: 60px;
        margin: auto;
        top: auto;
        bottom: 20px
    }

    .four-slider.owl-carousel.owl-theme .owl-nav [class*=owl-].owl-next {
        right: 0;
        left: 60px
    }

    .four-slider.owl-carousel.owl-theme .home-banner video {
        width: auto;
        height: 800px
    }

    .four-slider.owl-carousel.owl-theme .home-banner .home-banner-content {
        padding-top: 0
    }

    .inner-banner-shape .shape2 {
        display: none
    }

    .about-right-img .about-open-hours {
        bottom: -150px
    }

    .service-dots img {
        max-width: 260px
    }

    .service-dots-2 img {
        max-width: 300px
    }

    .service-open-hours {
        padding: 30px 10px
    }

    .about-content {
        margin-left: 0
    }

    .appointment-from {
        padding: 30px 20px
    }

    .appointment-from p {
        margin-bottom: 20px
    }

    .appointment-from .form-group {
        margin-bottom: 15px
    }

    .appointment-from .default-btn {
        margin-top: 5px
    }

    .appointment-img-2 {
        right: 0
    }

    .appointment-img-2 img {
        max-width: 650px
    }

    .appointment-widget-right .appointment-hours {
        bottom: -100px
    }

    .consultancy-area::before {
        height: 79.1%
    }

    .consultancy-area::after {
        height: 79.1%
    }

    .consultancy-content .consultancy-form {
        padding: 40px 25px
    }

    .emergency-shape-2 img {
        max-width: 856px
    }

    .emergency-content-ptb h2 {
        font-size: 34px!important
    }

    .emergency-area-bg::before {
        height: 92%
    }

    .prescription-content {
        margin-left: 70px
    }

    .prescription-content h2 {
        font-size: 34px
    }

    .prescription-content p {
        font-size: 18px
    }

    .prescription-shape img {
        max-width: 720px
    }

    .prescription-shape-2 img {
        max-width: 720px
    }

    .doctors-shape .doctors-shape-1 img {
        max-width: 260px
    }

    .doctors-shape .doctors-shape-2 img {
        max-width: 260px
    }

    .case-study-card .content {
        top: 15%
    }

    .case-study-card .content p {
        margin-bottom: 7px;
        max-width: 225px;
        font-size: 14px
    }

    .case-study-card .content .read-more-btn {
        font-size: 15px
    }

    .blog-shape-icon {
        top: -195px;
        font-size: 300px
    }

    .blog-card .content h3 {
        font-size: 18px
    }

    .blog-card-side ul li a h3 {
        font-size: 18px
    }

    .testimonials-slider {
        padding: 50px 30px;
        margin-left: -80px;
        margin-right: 80px
    }

    .testimonials-slider::before {
        top: 130px;
        font-size: 425px
    }

    .testimonials-slider-two::before {
        top: 108px
    }

    .testimonials-slider-two .owl-nav .owl-prev {
        left: 44%
    }

    .testimonials-slider-two .owl-nav .owl-next {
        right: 44%
    }

    .single-counter {
        padding: 60px 8px
    }

    .newsletter-area {
        max-width: 510px
    }

    .footer-widget h3 {
        font-size: 20px
    }

    .footer-widget .footer-contact-list li {
        font-size: 15px
    }

    .footer-widget .footer-list li {
        font-size: 15px
    }

    .footer-widget .social-link li {
        margin-right: 0
    }
}

@media only screen and (min-width: 1200px) and (max-width:1399px) {
    .four-slider.owl-carousel.owl-theme .owl-nav [class*=owl-] {
        left:0;
        right: 70px;
        top: auto;
        bottom: 80px;
        margin: auto
    }

    .four-slider.owl-carousel.owl-theme .owl-nav [class*=owl-].owl-next {
        right: 0;
        left: 70px
    }

    .four-slider.owl-carousel.owl-theme .home-banner video {
        width: auto
    }

    .third-slider.owl-carousel.owl-theme .owl-nav [class*=owl-] {
        left: 0
    }

    .third-slider.owl-carousel.owl-theme .owl-nav [class*=owl-].owl-next {
        right: 0
    }

    .banner-area .banner-img {
        bottom: -50px
    }

    .owl-carousel .owl-item img {
        display: block;
        max-width: 700px
    }

    .second-slider.owl-carousel.owl-theme .owl-nav [class*=owl-] {
        left: 5px
    }

    .second-slider.owl-carousel.owl-theme .owl-nav [class*=owl-].owl-next {
        right: 30px
    }

    .first-slider.owl-carousel.owl-theme .owl-nav [class*=owl-] {
        left: 5px
    }

    .first-slider.owl-carousel.owl-theme .owl-nav [class*=owl-].owl-next {
        right: 5px
    }
}

@media only screen and (min-width: 1400px) {
    .nav-bar {
        max-width:1370px
    }

    .banner-content h1 {
        font-size: 65px
    }

    .about-img img {
        width: 100%
    }

    .case-study-card .content {
        top: 22%
    }

    .case-study-card:hover::after {
        height: 93.4%
    }

    .appointment-from {
        padding: 35px 30px
    }

    .testimonials-slider {
        padding: 80px 60px
    }

    .testimonials-slider::before {
        top: 190px
    }

    .testimonials-slider .testimonials-item p {
        max-width: 450px;
        margin-left: auto;
        margin-right: auto
    }

    .emergency-img img {
        max-width: 445px
    }

    .case-article .case-details-img img {
        width: 100%
    }

    .case-article .case-article-other .article-img img {
        width: 100%
    }

    .terms-conditions-img img {
        width: 100%
    }

    .services-article .services-article-img img {
        width: 100%
    }

    .service-open-hours {
        max-width: 100%
    }

    .testimonials-slider-two::before {
        top: 285px
    }
}

@media only screen and (min-width: 1800px) {
    .banner-img {
        right:5%
    }

    .banner-img img {
        max-width: 1080px
    }

    .banner-img-2 {
        right: 15%
    }

    .banner-img-2 img {
        max-width: 100%
    }

    .emergency-shape {
        left: 5%
    }

    .appointment-img-2 {
        right: 20%
    }

    .appointment-shape {
        left: 34%
    }

    .service-dots {
        position: absolute;
        top: 0;
        left: 0
    }

    .service-details-shape img {
        max-width: 100%
    }

    .product-details-shape img {
        max-width: 100%
    }

    .case-study-shape img {
        max-width: 100%
    }

    .case-details-shape img {
        max-width: 100%
    }

    .doctor-tab-shape .shape1 img {
        max-width: 100%
    }

    .doctor-tab-shape .shape2 img {
        max-width: 100%
    }

    .insurance-img {
        right: 15%
    }

    .insurance-img img {
        max-width: 600px
    }

    .contact-shape img {
        max-width: 100%
    }

    .subscribe-shape {
        left: 25%
    }
}
